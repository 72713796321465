import {
  FrontEndLibraries,
  NavTabType,
  NavTabTypeWithChildren,
  ProductTypesType,
  UserPermissionsType,
} from "../global-nav.types";
import { ROUTES, ROUTE_KEYS } from "./routes";
import { WidgetPermissions } from "./widget-permissions.types";

export const isTabDropdown = (tab: NavTabType): tab is NavTabTypeWithChildren => !!tab?.children?.length;

// TEAM_SUBDOMAIN allows you to set the local linking to a specific subdomain of the Angular dev build e.g., 'dn.'
// BE SURE TO INCLUDE '.'
const TEAM_SUBDOMAIN = "";
// TODO - keep in shared location
// libs/common/src/lib/getLumaAppLogin.ts
export const getAngularLink = (url?: string): string => {
  const formattedUrl = url ?? window.origin;
  return formattedUrl.replace(/go(?:-(an|ch|dn|lm|sp))?(?=\.)/g, (match, teamSubdomain) =>
    teamSubdomain != null ? teamSubdomain : "app"
  );
};

export const getAdminAppLink = () => {
  if (isLocal()) {
    return "https://admin.bdev.lumafintech.com";
  }
  const currentHost = window.location.host;
  const adminHost = currentHost.replace("app.", "admin.").replace("go.", "admin.");
  return `https://${adminHost}`;
};

export const generateReactAppUrl = (route: string,) => {
  const baseUrl = window.origin
    .replace('http://', 'https://')
    .replace('localhost:4200', 'go.bdev.lumafintech.com')
    .replace('//app.', '//go.')
    .replace('//an.', '//go-an.')
    .replace('//ch.', '//go-ch.')
    .replace('//lm.', '//go-lm.')
    .replace('//pl.', '//go-pl.')
    .replace('//dn.', '//go-dn.')
    .replace('//pm.', '//go-pm.');
  return `${baseUrl}${route}`;
};


export const isProduction = () =>
  window.origin.includes("https://app.lumafintech.com") || window.origin.includes("https://go.lumafintech.com");
export const isStaging = () =>
  window.origin.includes("https://go.staging.lumafintech.com") ||
  window.origin.includes("https://app.staging.lumafintech.com");
export const isUAT = () =>
  window.origin.includes("https://go.buat.lumafintech.com") ||
  window.origin.includes("https://app.buat.lumafintech.com");
export const isQA = () =>
  window.origin.includes(".bqa.lumafintech.com");
export const isDev = () =>
  window.origin.includes(".bdev.lumafintech.com");
export const isLocal = () => window.origin.includes("localhost");
const STRUCTURED_PRODUCTS_PRODUCT_TYPES = {
  LINKED_CDS: "Market Linked CD's",
  STRUCTURED_NOTES: "Structured Notes",
};

const structuredProductTypes = [
  STRUCTURED_PRODUCTS_PRODUCT_TYPES.LINKED_CDS,
  STRUCTURED_PRODUCTS_PRODUCT_TYPES.STRUCTURED_NOTES,
];

const ANNUITY_PRODUCT_TYPES = {
  ALL: "ALL",
  DIA_SPIA: "DIA/SPIA",
  FIA: "FIA",
  FIXED_RATE: "Fixed Rate",
  RILA: "RILA",
  VARIABLE_ANNUITIES: "Variable Annuities",
};

const annuityProductTypes = [
  ANNUITY_PRODUCT_TYPES.VARIABLE_ANNUITIES,
  ANNUITY_PRODUCT_TYPES.DIA_SPIA,
  ANNUITY_PRODUCT_TYPES.FIA,
  ANNUITY_PRODUCT_TYPES.FIXED_RATE,
  ANNUITY_PRODUCT_TYPES.RILA,
];

export const isHigherEnvironment = () => isProduction() || isStaging() || isUAT();

export const isAnnuityOnly = (productTypes: ProductTypesType) =>
  productTypes.length > 0 && !structuredProductTypes.some((product) => productTypes.includes(product));

export const isStructuredProductsOnly = (productTypes: ProductTypesType) =>
  productTypes.length === 0 || !annuityProductTypes.some((product) => productTypes.includes(product));

export const generateAppUrl = (
  route: string,
  destinationLibrary: FrontEndLibraries,
  shouldNavigateToDevLocally = true
) => {
  // The following is a method in which we have an env flag that tells us which deployment env we're in.
  // This does not as yet exist, so we are using the second method
  //
  // let baseUrl = `https://${LIBRARY_SUBDOMAINS[destinationLibrary]}.${ENV_SUBDOMAINS[env]}.lumafintech.com`;
  // if (env === ENV.LOCAL) baseUrl = 'http://locahost:4200';

  let baseUrl = window.origin;
  const isDestinationAngular = destinationLibrary === FrontEndLibraries.ANGULAR;
  const destinationSubdomain = isDestinationAngular ? "app." : "go.";
  const currentSubdomain = isDestinationAngular ? "go." : "app.";

  if (isHigherEnvironment()) {
    // Only app/go subdomains exist in UAT/Staging/Prod
    baseUrl = baseUrl.replace(currentSubdomain, destinationSubdomain);
  } else if (!isLocal()) {
    // In lower environments determine the subdomain based on the one you are in
    // e.g., go-dn in React -> dn in Angular & lm in Angular -> go-lm in React
    baseUrl = isDestinationAngular ? getAngularLink(baseUrl) : generateReactAppUrl(baseUrl ?? window.origin);
  } else if (shouldNavigateToDevLocally) {
    // Locally, if desired, direct to app/go .bdev, or subdomain set in TEAM_SUBDOMAIN const
    baseUrl = `https://${destinationSubdomain}bdev.lumafintech.com`;
    baseUrl = TEAM_SUBDOMAIN ? baseUrl.replace(destinationSubdomain, TEAM_SUBDOMAIN) : baseUrl;
  }

  return `${baseUrl}${route}`;
};

export const createLinkToOtherApp = (
  destinationLibrary: FrontEndLibraries,
  routeKey: ROUTE_KEYS,
  shouldNavigateToDevLocally = true
) => {
  const route = ROUTES[routeKey][destinationLibrary];

  return {
    url: generateAppUrl(route, destinationLibrary, shouldNavigateToDevLocally),
  };
};

/**
 * getColdFusionBaseUrl: function that should be called with no arguments
 * that derives the correct environment-specific Cold Fusion base url based on the current window.origin.
 *
 * e.g.
 * https://app.bdev.lumafintech.com => https://ngportal.lumafintech.com
 * https://app.staging.lumafintech.com => https://stagingportal.lumafintech.com
 *
 * Optional argument 'origin' for testing how this function will work to convert various base urls
 * with an input rather than pulling directly from window.origin in the browser.
 */
export const getColdFusionBaseUrl = (origin?: string) => {
  const currentUrl = origin || window.origin;

  const updatedUrl = currentUrl
    .replace("http://", "https://")
    .replace("localhost:4200", "app.bdev.lumafintech.com")
    .replace("//app.", "//")
    .replace("//go.", "//")
    .replace(/(\/\/go).+?(?:\.)/g, "//")
    .replace("//an.", "//")
    .replace("//ch.", "//")
    .replace("//dn.", "//")
    .replace("//lm.", "//")
    .replace("//pl.", "//")
    .replace("//pm.", "//")
    .replace("bdev.", "ng")
    .replace("bqa.", "qa")
    .replace("buat.", "uat")
    .replace("staging.", "staging")
    .replace("lumafintech.com", "portal.lumafintech.com");
  // CF links are different for staging and production
  if (
    updatedUrl.startsWith("https://portal.lumafintech.com") ||
    updatedUrl.startsWith("https://stagingportal.lumafintech.com")
  ) {
    return updatedUrl.replace("lumafintech", "naviancapital");
  } else return updatedUrl;
};

export const getColdFusionLink = (page: string, section: string | null) => {
  return `${getColdFusionBaseUrl()}/cdfg/WebPages/${page}.cfm?${section ? `section=${section}&` : ""}`;
};

export const getColdFusionLinkAppVersion = (page: string, section: string | null) => {
  return `${getColdFusionBaseUrl()}/cdfg/app/${page}.cfm?${section ? `section=${section}&` : ""}`;
};

const filterLmFromSomeCompanies = (company: string | null | undefined) => {
  const COMPANIES_TO_BLOCK_LM_ACCESS = ["Highland Capital", "Ash Brokerage"];
  return company && COMPANIES_TO_BLOCK_LM_ACCESS.includes(company);
};

const filterLinks = <T extends NavTabType>(
  links: T[],
  userPermissions: UserPermissionsType,
  isProduction: boolean,
  company?: string | null | undefined
): T[] => {
  return links.filter((link) => {
    if (
      (!link.children && !link.route && !link.url && !link.isSubHeader) ||
      (link.children && !link.children?.length)
    ) {
      return false;
    }
    if (link.title === ROUTE_KEYS.COMPANY_ADMIN && isProduction) {
      return false;
    }

    if (link.title === ROUTE_KEYS.LIFECYCLE && filterLmFromSomeCompanies(company)) {
      return false;
    }

    return userHasAccess(link.permissions, userPermissions);
  });
};

export const filterNavTabs = (
  navTabs: NavTabType[],
  userPermissions: UserPermissionsType,
  isProduction: boolean,
  company?: string | null | undefined
): NavTabType[] => {
  const filteredNavTabs = filterLinks(navTabs, userPermissions, isProduction, company);

  filteredNavTabs.forEach((link) => {
    if (link.children) {
      link.children = filterLinks(link.children, userPermissions, isProduction, company);
      if (link.children?.length === 1) {
        const onlyChild = link.children[0];
        const uniquePermissions = new Set([...(link.permissions ?? []), ...(onlyChild.permissions ?? [])]);
        link.permissions = Array.from(uniquePermissions);
        link.children = [];
        link.route = onlyChild.route;
        link.url = onlyChild.url;
      }
    }
  });

  return filteredNavTabs;
};

export const determineNavRouteOrUrl = (
  frontEndLibrary: FrontEndLibraries,
  routeKey: ROUTE_KEYS,
  userPermissions: UserPermissionsType
): Partial<NavTabType> => {
  const route = ROUTES[routeKey][frontEndLibrary];
  // FOR HOME (dashboard)
  // If the user has access to the new dashboard or is already in the React app, route to the React app's dashboard
  if (routeKey === ROUTE_KEYS.HOME) {
    if (
      frontEndLibrary === FrontEndLibraries.ANGULAR &&
      !userHasAccess([WidgetPermissions.MenuCanViewLegacyDashboard], userPermissions)
    ) {
      return createLinkToOtherApp(FrontEndLibraries.REACT, routeKey);
    } else {
      return { route };
    }
  }

  // FOR CREATION HUB
  // For the time being if we are not in higher envs, route to the new creation hub products in React
  // In higher envs continue to utilize the existing products in Angular
  // Once the products are deployed in production
  // we can remove the env checks and simply route to the React deployments in all cases
  if (routeKey === ROUTE_KEYS.CREATE) {
    if (frontEndLibrary === FrontEndLibraries.REACT) return { route };
    else {
      return createLinkToOtherApp(FrontEndLibraries.REACT, routeKey);
    }
  }

  // FOR LIFECYCLE
  // Allow routing to both the existing Angular 'Lifecycle Manager' as well as the React 'Lifecycle Manager 2.0 Beta'
  // Each has its own associated permission for the respective dropdown link to be visible
  if (routeKey === ROUTE_KEYS.LIFECYCLE) {
    if (frontEndLibrary === FrontEndLibraries.REACT) return { route };
    else {
      return createLinkToOtherApp(FrontEndLibraries.REACT, routeKey);
    }
  }

  // FOR EVENT CONFIRMATIONS
  if (routeKey === ROUTE_KEYS.EVENT_NOTIFICATIONS) {
    if (frontEndLibrary === FrontEndLibraries.REACT) return { route };
    else {
      return createLinkToOtherApp(FrontEndLibraries.REACT, routeKey);
    }
  }

  // FOR EVENT CONFIRMATIONS ADMIN
  if (routeKey === ROUTE_KEYS.EVENT_NOTIFICATIONS_ADMIN) {
    if (frontEndLibrary === FrontEndLibraries.REACT) return { route };
    else {
      return createLinkToOtherApp(FrontEndLibraries.REACT, routeKey);
    }
  }

  // FOR ISSUER CONFIRMATIONS
  if (routeKey === ROUTE_KEYS.ISSUER_CONFIRMATIONS) {
    if (frontEndLibrary === FrontEndLibraries.REACT) return { route };
    else {
      return createLinkToOtherApp(FrontEndLibraries.REACT, routeKey);
    }
  }

  // FOR LIKELIHOOD OF CALL
  if (routeKey === ROUTE_KEYS.LIKELIHOOD_OF_CALL) {
    if (frontEndLibrary === FrontEndLibraries.REACT) return { route };
    else {
      return createLinkToOtherApp(FrontEndLibraries.REACT, routeKey);
    }
  }

  // FOR SECONDARIES
  if (routeKey === ROUTE_KEYS.TRADE) {
    if (frontEndLibrary === FrontEndLibraries.REACT) return { route };
    else {
      return createLinkToOtherApp(FrontEndLibraries.REACT, routeKey);
    }
  }


  if (routeKey === ROUTE_KEYS.ANNUITIES_LIFECYCLE) {
    if (frontEndLibrary === FrontEndLibraries.REACT) return { route };
    else {
      return createLinkToOtherApp(FrontEndLibraries.REACT, routeKey);
    }
  }

  // ALL REMAINING CASES
  if (frontEndLibrary === FrontEndLibraries.REACT) {
    return createLinkToOtherApp(FrontEndLibraries.ANGULAR, routeKey);
  } else {
    return { route };
  }
};

export const userHasAccess = (linkPermissions: string[] | undefined, userPermissions: string[]) =>
  !linkPermissions ||
  linkPermissions.some((linkPermission) =>
    userPermissions.some((userPermission) => linkPermission?.toLowerCase() === userPermission?.toLowerCase())
  );
